<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ title }}</span>
            <span @click="goBack()">
                <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
            </span>
        </el-card>
        <el-card>
            <!-- 情報入力 -->
            <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="ユーザー名:" prop="userName">
                            <el-input id="userName" placeholder="ユーザー名を入力してください" v-model="form.userName" :disabled="disabled"
                                style="width: 45%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="this.type == 'insert'">
                        <el-form-item label="パスワード:" prop="password">
                            <el-input id="password" placeholder="パスワードを入力してください" v-model="form.password" :disabled="disabled"
                                style="width: 45%"></el-input>
                        </el-form-item>
                    </el-col>


                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="電話番号:" prop="mobile">
                            <el-input id="mobile" placeholder="電話番号を入力してください" :disabled="disabled" v-model="form.mobile"
                                onkeyup="value=value.replace(/[^\d]/g,'')" style="width: 45%" maxlength="13"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="メールアドレス:" prop="email">
                            <el-input id="email" placeholder="メールアドレスを入力してください" v-model="form.email" :disabled="disabled"
                                style="width: 45%"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性別:" prop="gender">
                            <el-radio-group v-model="form.gender" :disabled="disabled">
                                <el-radio :label="'1'">男</el-radio>
                                <el-radio :label="'2'">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="備考:" prop="remarks" label-width="10%">
                            <el-input id="remarks" :disabled="this.type == 'details'" rows="5" type="textarea"
                                v-model="form.remarks" style="width: 75.5%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" v-if="this.type != 'insert'">
                        <el-form-item label="利用可能か:" prop="enabled">
                            <el-radio-group id="enabled" v-model="form.enabled" :disabled="disabled">
                                <el-radio :label="'1'">はい</el-radio>
                                <el-radio :label="'0'">いいえ</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="最終ログイン時間:" prop="lastLoginTime" label-width="10%">
                            <el-input id="lastLoginTime" disabled v-model="form.lastLoginTime"
                                style="width: 75.5%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime" v-if="this.type != 'insert'">
                    <el-col :span="12">
                        <el-form-item label="作成日時:" label-width="20%">
                            {{ createTime }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="更新日時:" label-width="20%">
                            {{ updateTime }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="作成者:" label-width="20%">
                            {{ createPersonName }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="更新者:" label-width="20%">
                            {{ updatePersonName }}
                        </el-form-item>
                    </el-col>
                </div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="" v-if="this.type != 'details'">
                            <el-button type="primary" @click="onSubmit('form')">{{ content }}</el-button>
                            <el-button @click="goBack">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>


    </div>
</template>

<script>
import userService from '@/api/user/userService'
import md5 from 'js-md5'
export default {
    inject: ["reload"],
    name: "GetUserDetails",
    components: {

    },
    data() {
        return {
            userService: new userService(),//インターフェース定義
            type: this.$route.query.type,// 遷移タイプ
            userId: this.$route.query.userId,//ユーザーID
            form: {
                userName: "",//ユーザー名
                password: "",//パスワード
                mobile: "",// 電話番号
                email: "",//メールアドレス
                gender: "1",//性別
                remarks: '',//備考
                enabled: '1',//利用可能かどうか
                lastLoginTime: ''//最終ログイン時間
            },

            title: "",// 詳細タイトル
            content: "",//ボタンテキスト
            disabled: false,//編集可能かどうか
            createTime: '',//作成日時
            updateTime: '',//更新日時
            createPersonName: '',//作成者
            updatePersonName: '',//更新者
            headerName: "",//パンくずリストのタイトル
            rules: {
                userName: [
                    {
                        required: true,
                        message: "名前を入力してください",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "パスワードを入力してください",
                        trigger: "blur"
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: "電話番号を入力してください",
                        trigger: "blur"
                    },
                    { min: 8, max: 15, message: '電話番号の形式が正しくありません', trigger: 'blur' }
                ],
                email: [
                    {
                        required: true,
                        message: "メールアドレスを入力してください",
                        trigger: "blur"
                    }
                ],
                gender: [
                    {
                        required: true,
                        message: "性別を選択してください",
                        trigger: "change"
                    }
                ],
                remarks: [
                    {
                        required: true,
                        message: "備考を入力してください",
                        trigger: "blur"
                    }
                ]
            },
        }
    },

    created() {
        if (this.type == "details") {
            this.title = "ユーザー管理詳細"
            this.headerName = "ユーザー管理詳細"
            this.disabled = true
            this.GetUserDetail()
        } else if (this.type == "update") {
            this.title = "ユーザー管理編集"
            this.headerName = "ユーザー管理編集"
            this.content = "保存"
            this.GetUserDetail()
        } else if (this.type == "insert") {
            this.title = "ユーザー管理新規追加"
            this.headerName = "ユーザー管理新規追加"
            this.content = "新規追加"
        }

    },

    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        //ユーザー管理の詳細を取得
        async GetUserDetail() {
            const date = {
                accessToken: localStorage.getItem("authToken"),
                currentRole: localStorage.getItem("currentRole"),
                userId: this.userId,//ユーザーID
            }
            this.userService.detail(date).then(res => {
                if (res.data.success == "true") {
                    this.form = res.data.result
                    this.createTime = res.data.result.createTime
                    this.updateTime = res.data.result.updateTime
                    this.createPersonName = res.data.result.updatePersonName
                    this.updatePersonName = res.data.result.updatePersonName
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        //ユーザー管理の新規追加
        onSubmit(formName) {
            if (this.type == "insert") {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        const date = {
                            accessToken: localStorage.getItem("authToken"),
                            currentRole: localStorage.getItem("currentRole"),
                            userName: this.form.userName,
                            password: md5(this.form.password),
                            mobile: this.form.mobile,
                            email: this.form.email,
                            gender: this.form.gender,
                            remarks: this.form.remarks
                        }
                        this.userService.add(date).then(res => {
                            if (res.data.success == "true") {
                                this.$message.success("保存に成功しました")
                                this.goBack();
                            } else {
                                this.$message.error(res.data.message)
                            }
                        })
                    }
                })
            } else if (this.type == "update") {
                this.UpdateUser(formName)
            }
        },
        //ユーザー管理の編集
        async UpdateUser(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const date = {
                        accessToken: localStorage.getItem("authToken"),
                        currentRole: localStorage.getItem("currentRole"),
                        userId: this.userId,
                        userName: this.form.userName,
                        mobile: this.form.mobile,
                        email: this.form.email,
                        gender: this.form.gender,
                        remarks: this.form.remarks,
                        enabled: this.form.enabled,
                    }
                    this.userService.update(date).then(res => {
                        if (res.data.success == "true") {
                            this.$message.success("保存に成功しました")
                            this.goBack();
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        //前のページに戻る
        goBack() {
            this.$router.go(-1)
        },
    }
}
</script>

<style lang="less" scoped>
.el-button1 {
    margin-top: 2px;
}

.el-card {
    margin-top: 15px;
}

textarea {
    height: 100px;
}
</style>
