import request from "@/utils/service.js"
export default class userService {
  
  list(data) {
    return request({
      url: 'm_getUserList',
      method: 'post',
      data: data
    })
  }
  detail(data) {
    return request({
      url: 'm_getUser',
      method: 'post',
      data: data
    })
  }
  add(data) {
    return request({
      url: 'm_addUser',
      method: 'post',
      data: data
    })
  }
  update(data) {
    return request({
      url: 'm_updateUser',
      method: 'post',
      data: data
    })
  }
  delete(data) {
    return request({
      url: 'm_deleteUser',
      method: 'post',
      data: data
    })
  }
}
